<template>
	<NuxtLink v-if="to && !disabled" :class="classNames" :style="{ width: width }" :to="to" :target="target">
		<div v-if="loading" class="button__spinner"/>
		<span v-else class="button__text">
			<slot />
		</span>
		<div v-if="appendIcon" class="button_append-icon">
			<slot name="append-icon"></slot>
		</div>
	</NuxtLink>
    <button
		v-else
        :class="classNames"
        :style="{ width: width }"
        :disabled="disabled"
        @click="click">
        <div v-if="loading" class="button__spinner"/>
        <span v-else class="button__text">
			<slot/>
		</span>
        <div v-if="appendIcon" class="button_append-icon">
            <slot name="append-icon"></slot>
        </div>
    </button>
</template>
<script>
export default {
    name: 'Button',
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        click: {
            type: Function,
        },
        width: {
            type: String,
            default: null,
        },
        size: {
            type: String,
            default: 'm',
        },
        color: {
            type: String,
            default: 'normal',
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        text: {
            type: Boolean,
            default: false,
        },
        rounded: {
            type: Boolean,
            default: true,
        },
        icon: {
            type: Boolean,
            default: false,
        },
        appendIcon: {
			type: Boolean,
			default: false
		},
        disabled: {
            type: Boolean,
            default: false,
        },
        borderHoverColor: {
            type: String,
            default: null,
        },
		target: {
			type: String,
			default: null,
		},
		to: {
			type: [String, Object],
			default: null
		}
    },

    computed: {
        classNames() {
            return {
				button: true,
                ['button_size_' + this.size]: true,
                ['button_color_' + this.color]: true,
                button_outlined: this.outlined,
                button_rounded: this.rounded,
                button_icon: this.icon,
                button_text: this.text,
                button_loading: this.loading,
                'button_border-color_hover': this.borderHoverColor,
            };
        },
        isDisabled() {
            return this.loading;
        },
    },
};
</script>
<style lang="scss" scoped>
.button {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 18px;
    height: 40px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $white;
    text-decoration: none;
    background: $gradient-1;
    border-radius: 8px;
    cursor: pointer;
    transition: color 0.15s linear, background-color 0.15s linear, border-color 0.15s linear, opacity 0.15s linear;

    &:before {
        content: '';
        position: absolute;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        border-radius: 5.6rem;
        background-color: $black;
        transition: background-color .2s;
        box-sizing: border-box;
    }

    &:disabled {
        pointer-events: none;
    }

    &_size {
        &_s {
            height: 28px;
            border-radius: 6px;
        }
        &_l {
            height: 50px;
			padding: 0 22px;
			font-size: 16px;
			font-weight: bold;
        }
    }

    &_color {
        &_normal {
            color: $white;

            &:hover,
            &:focus {
                color: $white;
            }

            &:disabled {
                opacity: 0.5;
            }
        }

        &_primary {
            //background-color: $primary1;
            //border-color: $primary1;
            &:before {
                content: none;
            }

            //&:hover,
            //&:focus {
            //    background-color: $primary2;
            //    border-color: $primary2;
            //}

            //&:disabled {
            //    background-color: $primary4;
            //    border-color: $primary4;
            //    color: $neutrals2;
            //}
        }

        &_secondary {
			background: $gradient-2;
            color: $white;

            //&:hover,
            //&:focus {
            //    color: $primary2;
            //    border-color: $primary2;
            //}
        }

        &_red {
            color: $red-light;
			background: $red-light;

            &:hover,
            &:focus {
                color: $white;
				background: $white;
                //border-color: $orange-2;
            }

			&:hover:before {
				background: $black !important;
			}
        }
    }

    &_rounded {
        border-radius: 40px;
    }

    &_icon {
        padding-inline: 0;
        width: 40px;
    }

    &_loading {
        text-align: center;
    }

    &-checkbox {
        justify-content: flex-start;

        &.checked {
            color: $white;
            border-color: $primary1;
        }

        &__text {
            margin-left: 8px;
            line-height: 1;
        }
    }

    &__text {
        position: relative;
        z-index: 1;
		display: flex;
		align-items: center;
		gap: 6px;
    }

    &__spinner {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid rgba(255, 255, 255, 0.8);
        border-top: 3px solid rgba(255, 255, 255, 0.1);
        animation: spin 1s linear infinite;
    }

    &:hover:before {
        background: transparent;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
